import React, { useState } from 'react';
import styles from './Contacts.module.scss';
import Form from './Form/Form';
import Modal from './Modal/Modal'
import monobank from '../../fig/monobank.png';
import welmex from '../../fig/logo.png';

const Contacts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <footer id="contacts" className={styles.container}>
        <h2 className={styles.title}>КОНТАКТИ</h2>
        <p>м. Київ, вул. Героїв Мариуполя 2-Б</p>
        <p>(097) 880-82-69</p>
        <p>welmexplus@gmail.com</p>
        <button className={styles.callBack} onClick={toggleModal}>Передзвоніть мені</button>
        <Modal show={isModalOpen} onClose={toggleModal}>
          <Form />
        </Modal>
        <div className={styles.logos}>
          <img className={styles.welmex} src={welmex} alt="Welmex" />
          <img className={styles.monobank} src={monobank} alt="Monobank" />
        </div>
    </footer>
  )
}

export default Contacts