import React, { useState, useEffect, useRef } from 'react';
import styles from './Objects.module.scss';
import obj1 from '../../fig/obj1.jpg'
import obj2 from '../../fig/obj2.jpg'
import obj3 from '../../fig/obj3.jpg'
import obj4 from '../../fig/obj4.jpg'
import obj5 from '../../fig/obj5.jpg'
import obj6 from '../../fig/obj6.jpg'
import obj7 from '../../fig/obj7.jpg'
import obj8 from '../../fig/obj8.jpg'
import obj9 from '../../fig/obj9.jpg'
import obj10 from '../../fig/obj10.jpg'
import obj11 from '../../fig/obj11.jpg'
import obj12 from '../../fig/obj12.jpg'
import obj13 from '../../fig/obj13.jpg'
import obj14 from '../../fig/obj14.jpg'
import obj15 from '../../fig/obj15.jpg'
import obj16 from '../../fig/obj16.jpg'
import obj17 from '../../fig/obj17.jpg'
import obj18 from '../../fig/obj18.jpg'
import obj19 from '../../fig/obj19.jpg'
import obj20 from '../../fig/obj20.jpg'
import obj21 from '../../fig/obj21.jpg'
import obj22 from '../../fig/obj22.jpg'
import obj23 from '../../fig/obj23.jpg'
import obj24 from '../../fig/obj24.jpg'
import obj25 from '../../fig/obj25.jpg'
import obj26 from '../../fig/obj26.jpg'
import obj27 from '../../fig/obj27.jpg'
import obj28 from '../../fig/obj28.jpg'
import obj29 from '../../fig/obj29.jpg'
import obj30 from '../../fig/obj30.jpg'
import obj31 from '../../fig/obj31.jpg'
import obj32 from '../../fig/obj32.jpg'
import obj33 from '../../fig/obj33.jpg'
import obj34 from '../../fig/obj34.jpg'
import obj35 from '../../fig/obj35.jpg'
import obj36 from '../../fig/obj36.jpg'
import obj37 from '../../fig/obj37.jpg'
import obj38 from '../../fig/obj38.jpg'
import obj39 from '../../fig/obj39.jpg'
import obj40 from '../../fig/obj40.jpg'
import obj41 from '../../fig/obj41.jpg'
import obj42 from '../../fig/obj42.jpg'
import obj43 from '../../fig/obj43.jpg'
import obj44 from '../../fig/obj44.jpg'
import obj45 from '../../fig/obj45.jpg'
import obj46 from '../../fig/obj46.jpg'
import obj47 from '../../fig/obj47.jpg'
import obj48 from '../../fig/obj48.jpg'
    



const Objects = () => {
    // Массив импортированных изображений
    const images = [obj1, obj2, obj3, obj4, obj5, obj6, obj7, obj8, obj9, obj10, obj11, obj12, obj13, obj14, obj15, obj16, obj17, obj18, obj19, obj20, obj21, obj22, obj23, obj24, obj25, obj26, obj27, obj28, obj29, obj30, obj31, obj32, obj33, obj34, obj35, obj36, obj37, obj38, obj39, obj40, obj41, obj42, obj43, obj44, obj45, obj46, obj47, obj48];

    // Состояние для выбранного изображения
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const thumbnailsContainerRef = useRef(null);

    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
    };

    const updateSelectedImage = (direction) => {
        const currentIndex = images.indexOf(selectedImage);
        let newIndex;

        if (direction === 'left') {
            newIndex = currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1;
        } else {
            newIndex = currentIndex + 1 === images.length ? 0 : currentIndex + 1;
        }

        setSelectedImage(images[newIndex]);
    };

    const scrollThumbnails = (direction) => {
        updateSelectedImage(direction);
    };

    // Эффект для прокрутки миниатюрного изображения в видимую область
    useEffect(() => {
        const thumbnailsContainer = thumbnailsContainerRef.current;
        const activeThumbnail = thumbnailsContainer.querySelector(`.${styles.activeThumbnail}`);

        if (activeThumbnail) {
            const thumbnailOffset = activeThumbnail.offsetLeft + activeThumbnail.offsetWidth / 2;
            const scrollPosition = thumbnailOffset - thumbnailsContainer.offsetWidth / 2;
            thumbnailsContainer.scrollLeft = scrollPosition;
        }
    }, [selectedImage]);

    return (
        <div id="objects" className={styles.ourStudentsWrapper}>
            <p className={styles.title}>ГОТОВІ ОБ'ЄКТИ</p>
            <div className={styles.selectedImage}>
                <img src={selectedImage} alt="Selected" />
            </div>
            <button className={`${styles.thumbnailsButton} ${styles.left}`} onClick={() => scrollThumbnails('left')}>&lt;</button>
            <div className={styles.thumbnailsWrapper}>
                <div className={styles.thumbnails} ref={thumbnailsContainerRef}>
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Thumbnail ${index}`}
                            onClick={() => handleThumbnailClick(image)}
                            className={image === selectedImage ? `${styles.thumbnail} ${styles.activeThumbnail}` : styles.thumbnail}
                        />
                    ))}
                </div>
            </div>
            <button className={`${styles.thumbnailsButton} ${styles.right}`} onClick={() => scrollThumbnails('right')}>&gt;</button>
        </div>
    );
};

export default Objects;
