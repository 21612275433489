import React from 'react';
import styles from './Advantages.module.scss'; // Убедитесь, что название файла стилей совпадает с импортом

const Advantages = () => {
//   const advantagesList = [
//     "Нас знають понад 20 років",
//     "З нами вигідно співпрацювати",
//     "До нас повертаються",
//     "Нас рекомендують",
//     "У нас навчаються",
//     "У нас немає незавершених проектів",
//     "Гарантія"
//   ];

  return (
    <div id="advantages" className={styles.container}>
      <p className={styles.title}>ПЕРЕВАГИ</p>
      <div className={styles.advantages}>
        <div className={styles.row}>
            <div className={styles.numberCircle}>1</div>
            <div className={styles.text}>Нас знають понад 30 років</div>   
        </div>
        <div className={styles.row}>
            <div className={styles.numberCircle}>2</div>
            <div className={styles.text}>З нами вітаються</div>
        </div>
        <div className={styles.row}>
            <div className={styles.numberCircle}>3</div>
            <div className={styles.text}>До нас повертаються</div>
        </div>
        <div className={styles.row}>
            <div className={styles.numberCircle}>4</div>
            <div className={styles.text}>Нас рекомендують</div>
        </div>
        <div className={styles.row}>
            <div className={styles.numberCircle}>5</div>
            <div className={styles.text}>У нас навчаються</div>
        </div>
        <div className={styles.row}>
            <div className={styles.numberCircle}>6</div>
            <div className={styles.text}>У нас немає незавершених проектів</div>
        </div>
        <div className={styles.row}>
            <div className={styles.numberCircle}>7</div>
            <div className={styles.text}>Гарантія</div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
