import React from 'react';
import styles from './Services.module.scss'; // Убедитесь, что у вас есть SCSS файл с таким именем
// import image1 from '../../fig/1.png'
import image2 from '../../fig/2.png'
import image3 from '../../fig/3.png'
import image4 from '../../fig/6.png'
import { Link } from 'react-router-dom';

const services = [
  // { name: 'Интернет магазин', image: image1 },
  { name: 'Сауни "під ключ"', image: image2, path: '/sauna' },
  { name: 'Устаткування для саун', image: image3, path: '/equipment' },
  // { name: 'Устаткування для саун', image: image3, path: 'https://welmexplus.prom.ua/ua/' },
  { name: 'Хаммам "під ключ"', image: image4, path: '/hammam' }
];

const Services = () => {
  return (
  <div id="services" className={styles.container}>
  <p className={styles.title}>НАШІ ПОСЛУГИ</p>
  <div className={styles.services}>
    {services.map((service, index) => (
      <Link 
        to={service.path !== '#' ? service.path : ''}
        key={index}
        onClick={(e) => {
          if (service.path === '#') {
            e.preventDefault();
          }
        }}
      >
        <div className={styles.card}>
          <img src={service.image} alt={service.name} className={styles.image} />
          <div className={styles.name}>{service.name}</div>
        </div>
      </Link>
    ))}
  </div>
  </div>
  );
};

export default Services;
