import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useSpring, animated } from '@react-spring/web';
import styles from './Header.module.scss';
import logo from '../../fig/logo.png';
import logoMono from '../../fig/logoMono.png';
import videoBack from '../../fig/back1.mp4'


const Header = () => {
  let navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleAnchorClick = (anchor) => {
    if (window.location.pathname !== '/') {
      navigate('/' + anchor);
    } else {
      const element = document.getElementById(anchor.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleLinkClick = (e) => {
    const anchor = e.target.getAttribute('href');
    e.preventDefault();
    closeMenu();
    handleAnchorClick(anchor);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };


  return (
    <div className={styles.container}>
      <video autoPlay loop muted className={styles.videoback}>
        <source src={videoBack} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <header className={styles.header}>
        <nav className={styles.navigation}>
          <img className={styles.logo} src={logo} alt="Logo" />
          <button className={styles.menuButton} onClick={toggleMenu}>
            ☰ {/* Это символ меню (можно заменить на иконку) */}
          </button>
          <ul className={`${styles.menu} ${isMenuOpen ? styles.menuOpen : ''}`}>
            {isMenuOpen && (
              <button className={styles.closeButton} onClick={toggleMenu}>
                &times; {/* Это символ крестика */}
              </button>
            )}
            <li><a href='#advantages' className={styles.link} onClick={handleLinkClick}>Переваги</a></li>
            <li><a href='#services' className={styles.link} onClick={handleLinkClick}>Послуги</a></li>
            <li><a href='#about' className={styles.link} onClick={handleLinkClick}>Про нас</a></li>
            <li><a href='#objects' className={styles.link} onClick={handleLinkClick}>Готові об'єкти</a></li>
            <li><a href='#contacts' className={styles.link} onClick={handleLinkClick}>Контакти</a></li>
          </ul>
        </nav>
      </header>
      <main className={styles.banner}>
          <div className={styles.bannerText}>
            <h1 className={styles.bannerb}>ЛАЗНІ</h1>
            <h1 className={styles.bannerh}>ХАММАМИ</h1>
            <h1 className={styles.banners}>САУНИ</h1>
            <h1 className={styles.bannert}>"ПІД КЛЮЧ"</h1>
          </div>
          <div className={styles.logoMono}>
            <img src={logoMono} alt="Logo" />
          </div>
      </main>
    </div>
  );
};

export default Header;
