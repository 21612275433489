import React, { useState, useEffect, useRef } from 'react';
import Contacts from '../Contacts/Contacts'

import styles from './SaunaKey.module.scss'
import image1 from '../../fig/4-1.jpg'
import image2 from '../../fig/obj1.jpg'
import image3 from '../../fig/obj2.jpg'
import image4 from '../../fig/obj3.jpg'
import image5 from '../../fig/obj4.jpg'
import image6 from '../../fig/obj5.jpg'
import image7 from '../../fig/obj6.jpg'
import image8 from '../../fig/obj7.jpg'
import image9 from '../../fig/obj8.jpg'
import image10 from '../../fig/obj9.jpg'
import image11 from '../../fig/obj10.jpg'
import image12 from '../../fig/obj11.jpg'
import image13 from '../../fig/obj12.jpg'
import image14 from '../../fig/obj13.jpg'
import image15 from '../../fig/obj14.jpg'
import image16 from '../../fig/obj15.jpg'
import image17 from '../../fig/obj16.jpg'
import image18 from '../../fig/obj17.jpg'
import image19 from '../../fig/obj18.jpg'
import image20 from '../../fig/obj19.jpg'
import image21 from '../../fig/obj20.jpg'
import image22 from '../../fig/obj21.jpg'
import image23 from '../../fig/obj22.jpg'
import image24 from '../../fig/obj23.jpg'
import image25 from '../../fig/obj24.jpg'
import image26 from '../../fig/obj25.jpg'
import image27 from '../../fig/obj26.jpg'
import image28 from '../../fig/obj27.jpg'
import image29 from '../../fig/obj28.jpg'
import image30 from '../../fig/obj29.jpg'
import image31 from '../../fig/obj30.jpg'
import image32 from '../../fig/obj31.jpg'
import image33 from '../../fig/obj32.jpg'
import image34 from '../../fig/obj33.jpg'
import image35 from '../../fig/obj34.jpg'
import image36 from '../../fig/obj35.jpg'
import image37 from '../../fig/obj36.jpg'
import image38 from '../../fig/obj47.jpg'

import Modal from './Modal/Modal'; // Путь к вашему компоненту Modal
import OrderForm from './OrderForm/OrderForm';

const SaunaKey = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    
    const [table1Data, setTable1Data] = useState([]);
    const [table2Data, setTable2Data] = useState([]);
    const [table3Data, setTable3Data] = useState([]);
    const [table4Data, setTable4Data] = useState([]);
    const [table5Data, setTable5Data] = useState([]);

    const API_KEY = 'AIzaSyCcUghkzabiyEkdW-RmTIvhU50j5h8OAQs'; // Замените на ваш API ключ
    const SHEET_ID = '1ZLRrV8yVxGFk6zRdmheD0gwtFoRKx1fG-MIhF7Xtuvo';

    const fetchData = async (sheetName) => {
        try {
          const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${sheetName}?key=${API_KEY}`);
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          return data.values; // Возвращаем массив данных
        } catch (error) {
          console.error("There was an error fetching the data: ", error);
          return []; // Возвращаем пустой массив в случае ошибки
        }
      };
      

    useEffect(() => {
        fetchData('Сауна-мини').then(data => {
          setTable1Data(data);
        });
      
        fetchData('Сауна-миди').then(data => {
          setTable2Data(data);
        });
      
        fetchData('Сауна').then(data => {
          setTable3Data(data);
        });

        fetchData('Сауна-CLUB').then(data => {
            setTable4Data(data);
        });

        fetchData('Баня').then(data => {
            setTable5Data(data);
        });
      
        
    }, []);

      const renderTable = (tableData) => {
        // Проверяем, есть ли данные перед тем, как их рендерить
        if (!tableData || tableData.length === 0) {
          return <div>Loading...</div>; // Или другой элемент, который будет показан во время загрузки
        }
      
        return (
          <table>
            <thead>
              <tr>
                {tableData[0].map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.slice(1).map((row, index) => (
                <tr key={index}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      };
      

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29, image30, image31, image32, image33, image34, image35, image36, image37, image38];

    // Состояние для выбранного изображения
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const thumbnailsContainerRef = useRef(null);

    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
    };

    const updateSelectedImage = (direction) => {
        const currentIndex = images.indexOf(selectedImage);
        let newIndex;

        if (direction === 'left') {
            newIndex = currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1;
        } else {
            newIndex = currentIndex + 1 === images.length ? 0 : currentIndex + 1;
        }

        setSelectedImage(images[newIndex]);
    };

    const scrollThumbnails = (direction) => {
        updateSelectedImage(direction);
    };

    // Эффект для прокрутки миниатюрного изображения в видимую область
    useEffect(() => {
        const thumbnailsContainer = thumbnailsContainerRef.current;
        const activeThumbnail = thumbnailsContainer.querySelector(`.${styles.activeThumbnail}`);

        if (activeThumbnail) {
            const thumbnailOffset = activeThumbnail.offsetLeft + activeThumbnail.offsetWidth / 2;
            const scrollPosition = thumbnailOffset - thumbnailsContainer.offsetWidth / 2;
            thumbnailsContainer.scrollLeft = scrollPosition;
        }
    }, [selectedImage]);

    return (
        <div className={styles.container}>
            <p className={styles.title}>Сауни "під ключ"</p>
            <p className={styles.start}>ФОП Фабрикант Валерій Йосипович пропонує Вам будівництво саун, турецьких лазень, інфрачервоних кабін будь-якої складності і конфігурації</p>
            <b className={styles.center}>Наші роботи:</b>
            <div className={styles.gallery}>
                <div className={styles.selectedImage}>
                    <img src={selectedImage} alt="Selected" />
                </div>
                <button className={`${styles.thumbnailsButton} ${styles.left}`} onClick={() => scrollThumbnails('left')}>&lt;</button>
                <div className={styles.thumbnailsWrapper}>
                    <div className={styles.thumbnails} ref={thumbnailsContainerRef}>
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Thumbnail ${index}`}
                                onClick={() => handleThumbnailClick(image)}
                                className={image === selectedImage ? `${styles.thumbnail} ${styles.activeThumbnail}` : styles.thumbnail}
                            />
                        ))}
                    </div>
                </div>
                <button className={`${styles.thumbnailsButton} ${styles.right}`} onClick={() => scrollThumbnails('right')}>&gt;</button>
            </div>
            <div className={styles.text}>
                <p>ФОП Фабрикант Валерій Йосипович проектує і будує сауни та лазні будь-якої складності і конфігурації. Серед них: сауна в квартиру, мобільний сауна, турецька сауна і багато іншого.</p>
                <div className={styles.order}>
                    <b className={styles.orderText}>Щоб отримати аукціонно низьку ціну на сауну або турецьку лазню, безкоштовний виїзд фахівця на об'єкт (до 30 км від Києва) і наше розрахункову пропозицію натисніть тут.</b>
                    <button className={styles.orderButton} onClick={handleOpenModal}>Зробити замовлення</button>
                    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                        <OrderForm />
                    </Modal>
                </div>
                <p><b>Наші сауни не бояться температур понад 90°С</b>, оскільки виготовляються з високоякісних матеріалів (листяних порід найвищої якості);</p>
                <p><b>Довговічні й гігієнічні</b> - конструкцією передбачено, що їх можна мити (плитковий підлогу, наявність каналізаційного стоку);</p>
                <p><b>Пожежобезпечні</b> - ми дотримуємося протипожежні розриви, передбачені СНиП, встановлюємо теплові відбивачі.</p>
                <div className={styles.price}><b>Ціни (в у.о.) на "сауни під ключ" (стандартна комплектація *)</b></div>
                <p><b>1. Сауна-міні.</b> Обсяг від 2,5 до 5,5 м.куб. Висота парилки 2,0-2,2 м. Розрахована на 2-4 парильщика. Може бути обладнана <b>сауна в квартиру</b>, або на дачу.</p>
                <div className={styles.table}>
                    {renderTable(table1Data)}
                </div>
                <p><b>2. Сауна-міді.</b> Обсяг від 6,6 до 10,6 м.куб. Висота парилки 2,1-2,3 м. Розрахована на 2-5 сидячих місць або 1-2 місця для лежання. Сімейний варіант сауни</p>
                <div className={styles.table}>
                    {renderTable(table2Data)}
                </div>
                <p><b>3. Сауна.</b> Обсяг від 10 до 14 м.куб. Висота парилки 2,1-2,3 м. Розрахована на 6 cідячіх місць або 2-3 місця для лежання. Ідеальний варіант для заміського будинку</p>
                <div className={styles.table}>
                    {renderTable(table3Data)}
                </div>
                <p><b>4. Сауна-CLUB.</b> Обсяг від 14 до 26 м.куб. Висота парилки 2,2-2,4 м. Може використовуватися як громадська сауна в спорткомплексах, котеджах</p>
                <div className={styles.table}>
                    {renderTable(table4Data)}
                </div>
                <p><b>5. Лазня.</b> Обсяг від 16 м.куб до 30 м.куб. Обладнана дерев'яною піччю HARVIA. Вартість вказана без димоходу.</p>
                <div className={styles.table}>
                    {renderTable(table5Data)}
                </div>
                <div className={styles.saunaIncludes}>
                    <h2>* У стандартну комплектацію нашої сауни входить:</h2>
                    <ol>
                        <li className={styles.numbers}>
                        <strong>Устаткування і матеріали:</strong>
                            <ul>
                                <li>електрокам'янка HARVIA (Фінляндія) з вбудованим пультом управління;</li>
                                <li>двері зі скла термостійкого;</li>
                                <li>теплоізоляція ISOVER;</li>
                                <li>світильник термостійкий;</li>
                                <li>пароізоляція (фольга + скотч алюмінієвий);</li>
                                <li>плитка на підлогу;</li>
                                <li>трапик на підлогу;</li>
                                <li>підголівники, подспинники;</li>
                                <li>огорожі світильника, печі;</li>
                                <li>термоотражательний екран;</li>
                                <li>система вентиляції;</li>
                                <li>вагонка (вільха, липа) для обшивки парилки;</li>
                                <li>брус на полки (вільха, липа);</li>
                                <li>плитка термостійка або бут для облицювання місця установки печі.</li>
                            </ul>
                        </li>
                        <li className={styles.numbers}>
                            <strong>Роботи:</strong>
                            <ul>
                                <li>монтажні, столярні;</li>
                                <li>електротехнічні (включаючи заходи щодо вирівнювання потенціалу);</li>
                                <li>облицювальні (обробка місця біля печі, укладання плитки статі).</li>
                            </ul>
                        </li>
                        <li className={styles.numbers}><strong>Доставка вартість обладнання і матеріалів;</strong></li>
                        <li className={styles.numbers}><strong>Гарантійне обслуговування - 12 місяців.</strong></li>
                    </ol>
                </div>
                <div className={styles.add}>
                    <h2>Крім того сауна може бути обладнана:</h2>
                    <ul>
                        <li>виносним пультом - 380 у.о .;</li>
                        <li>комплектом аксесуарів (шайка, лійка, годинник пісочний, термометр, гігрометр, щітки, ароматизатор, антисептик) - від 60 у.о .;</li>
                        <li>брусом абаш на полицях.</li>
                    </ul>
                    <p>Всі роботи по монтажу сауни виробляються дипломованими фахівцями, які враховують всі побажання клієнта і виконують свою роботу, орієнтуючись на якість кінцевого результату. Всі роботи виконуються в максимально короткі терміни.</p>
                </div>
            </div>
            <Contacts />
        </div>
    );
}

export default SaunaKey