import React from 'react'
import styles from './TextVideo.module.scss'
import video1 from '../../fig/video1.mp4'
import video4 from '../../fig/video2.MOV'
import video2 from '../../fig/ledVideo1.mp4'
import video3 from '../../fig/ledVideo2.mp4'
import paw from '../../fig/24.png'


const TextVideo = () => {
  return (
    <div className={styles.container}>
      <div className={`${styles.row}`}>
      <iframe className={styles.video} src="https://www.youtube.com/embed/X0PmyoKY82w?si=_2s6TGqAZbH0Bj-Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        <div className={styles.text}>
            <p><b>ФОП Фабрикант Валерій Йосипович</b> - це будівництво саун і лазень на замовлення якісно та у визначені терміни. Ми надаємо нашим клієнтам знижки на обладнання при замовленні будівництва під ключ, будівництво тіларріумов, хаммамов. Ми також продаємо обладнання (печі, парогенератори). Також ми надаємо дворічну гарантію на наші будівлі.</p> 
            <p>Триває <b>будівництво саун</b> від двох до шести тижнів. За нашими плечима вже 22 роки досвіду, а в нашій команді працюють тільки справжні професіонали. Ми надаємо консультацію для клієнтів на безкоштовній основі, а також існує програма лояльності. Серед наших переваг: </p>
            <p>- Демократична цінова політика;</p>
            <p>- Професійне обслуговування;</p>
            <p>- Нові технології в будівництві;</p>
            <p>- Індивідуальний підхід до кожного клієнта.</p>
        </div> 
      </div>
      <div className={`${styles.row} ${styles.reverse}`}>
        <iframe className={styles.video} src="https://www.youtube.com/embed/hurExtjSI5o?si=bCUkwkkr8GDKmc1e" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        <div className={styles.text}>
            <h6>Виготовлення лазень і саун під ключ</h6>
            <p>Ми будуємо сауни і лазні за всіма будівельними вимогами і враховуємо побажання наших клієнтів. Результат нашої роботи - це комфортна і безпечна сауна, або лазня.</p>
        </div>
      </div>
      <div className={`${styles.row}`}>
        <video controls>
            <source src={video1} type="video/mp4" />
            Ваш браузер не поддерживает видео тег.
        </video>
        <div className={styles.text}>
            <h6>Будівництво турецьких лазень під ключ</h6>
            <p>Ми надаємо будівництво унікальної турецької лазні з елементами східного декору і мозаїчної облицюванням стін і підлоги. Це чудовий варіант для любителів екзотики. Крім того ми надаємо <b>ремонт саун і лазень.</b> Починка сантехніки, усунення інших неполадок, косметичний ремонт. У нас Ви також зможете <b>купити твердопаливні дров'яні печі для саун.</b> Вони можуть бути як зі скляними, так і з чавунними дверцятами, бойлерні кам'янки, електрокам'янки та багато інших печі. Серед електрокаменок у нас є великий вибір: для великої сімейної лазні, для громадської лазні. Деякі моделі включають в себе парогенератори для масел і трав.</p>
        </div>
      </div>
      <div className={`${styles.row} ${styles.reverse}`}>
        <video controls>
            <source src={video2} type="video/mp4" />
            Ваш браузер не поддерживает видео тег.
        </video>
        <div className={styles.text}>
            <h6>Користь від сауни і лазні</h6>
            <p>- Міцне здоров'я;</p>
            <p>- Чудовий настрій;</p>
            <p>- Підтягнута фігура;</p>
            <p>- Ніжна і красива шкіра;</p>
            <p>- Чисті і здорові легені;</p>
            <p>- Здорове волосся.</p>
        </div>
      </div>
      <div className={`${styles.row}`}>      
        <video className={styles.video} controls>
            <source src={video3} type="video/mp4" />
            Ваш браузер не поддерживает видео тег.
        </video>
        <div className={styles.text}>
            <h6>Основні напрямки діяльності</h6>
            <p>Ми надаємо <b>будівництво турецької лазні, будівництво фінської</b> та <b>російської лазні</b>. Також ми зводимо соляні кімнати будь-якої складності, виробляємо облицювальні роботи, ремонти лазень, квартир, укладання «теплої підлоги». Також ми займаємося продажем техніки для саун. У нас можна <b>купити дров'яну піч, кам'янку, твердопаливну піч</b> і багато іншого. У нашому асортименті є товар від відомих брендів: Sawo, EOS, Dr.Kern, Helo, Tylo, Klafs, Saunatec, Harvia і & nbsp; ін.</p>
        </div>
      </div>
      <div className={styles.text1}>
          <video className={styles.video} controls>
            <source src={video4} type="video/mp4" />
            Ваш браузер не поддерживает видео тег.
          </video>
          <h6>Переваги наших саун</h6>
          <b>Висока зносостійкість</b>
          <p>Лазні зроблені з листяних порід, тому не бояться температур понад 90 ° С. Будьте впевнені в тому, що наші лазні на 100% стійкі до різного роду факторів!</p>
          <b>Довговічність</b>
          <p>Сауни будуються за інноваційними технологіями, завдяки чому служать дуже довго. Ви зможете відпочивати і релаксувати в парильні не один десяток років!</p>
          <b>Унікальний дизайн</b>
          <p>Поєднання каменю, мозаїки та кераміки створює унікальну художню композицію. Ми пропонуємо будь-які стильові рішення для створення гармонійної обстановки.</p>
          <b>Гігієнічність</b>
          <p>Конструкція передбачає можливість легкої і гігієнічної прибирання. Плитковий підлогу, наявність каналізаційного стоку - ці елементи дозволять підтримувати баню в чистоті.</p>
          <b>Пожежна безпека</b>
          <p>В будівництві хаммамов дотримуються всі пожежні норми і вимоги, передбачені СНиП. 100% безпека гарантована!</p>
          <b>Комфортабельність</b>
          <p>Для зручності оснастимо сауну освітленням, фітоколбой, столиком для масажу, соляним туманом, генератором мильної піни, декоративними колонами, арками і фігурами. Наша команда - це команда справжніх професіоналів. У нас працюють найкращі будівельники, монтажники, дизайнери, техніки. Ми обслуговуємо об'єкти по всій Україні (як по гарантії, так і післягарантійне обслуговування). Ви зможете також замовити 3D модель Вашого проекту у наших дизайнерів. Клієнти оплачують роботу нашої команди поетапно. Ви зможете платити тільки в міру виконаної роботи.</p>
          <b>Створюємо баню з нуля!</b>
          <div className={styles.paw}>
            <p>Замовлення на сайті або по телефону. Візит зморщок на об'єкт. Створення креслень і кошторису. Аванс - 80% або оплата поетапно. Остаточний розрахунок з приймання. Гарантійний сервіс. Наш сайт - це зручна консультація і замовлення будівництва лазні, або сауни. Ви можете зателефонувати, або написати нам і отримати безкоштовну консультацію з усіх питань, пов'язаних з будівництвом лазні і сауни. Вказавши всі Ваші побажання - Ви отримаєте повну вартість послуги та її оплату Ви зможете зробити поетапно, або ж внести 80% передоплати.</p>
            <img src={paw} alt="image1" />
          </div>
      </div>
  </div>
  )
}

export default TextVideo