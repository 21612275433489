import React, { useState } from 'react';
import styles from './OrderForm.module.scss'

const OrderForm = () => {
  const [choice, setChoice] = useState('');

  const handleSelectChange = (event) => {
    setChoice(event.target.value);
  };
  return (
    <div className={styles.container}>
        <form
            action="https://formspree.io/f/mbjnzyrp"
            method="POST"
        >
            <label>
                ПІБ
                <input type="message" name="message" />
            </label>
            <label>
                Телефон
                <input type="message" name="message" />
            </label>
            <label>
                E-mail
                <input type="email" name="email" />
            </label>
            <label>
                Оберіть
                <select value={choice} onChange={handleSelectChange} className="selectField">
                <option value="">Оберіть</option>
                <option value="sauna">Сауна</option>
                <option value="hamam">Хамам</option>
                </select>
            </label>
            <label>
                Довжина (мм)
                <input type="message" name="message" />
            </label>
            <label>
                Ширина (мм)
                <input type="message" name="message" />
            </label>
            <label>
                Висота (мм)
                <input type="message" name="message" />
            </label>
            <label>
                Адреса
                <input type="message" name="message" />
            </label>
            <button type="submit">Замовити</button>
        </form>
    </div>
  )
}

export default OrderForm