import React, {useState, useRef, useEffect} from 'react'
import styles from './HamaKey.module.scss'
import image1 from '../../fig/obj40.jpg'
import image2 from '../../fig/obj41.jpg'
import image3 from '../../fig/obj42.jpg'
import image4 from '../../fig/obj43.jpg'
import image5 from '../../fig/obj44.jpg'
import image6 from '../../fig/obj45.jpg'
import image7 from '../../fig/obj46.jpg'
import image8 from '../../fig/obj48.jpg'
import image9 from '../../fig/ham3.jpg'
import image10 from '../../fig/ham4.jpg'
import image11 from '../../fig/ham5.jpg'
import image12 from '../../fig/ham6.jpg'
import image13 from '../../fig/ham7.jpg'
import Contacts from '../Contacts/Contacts'


const HamaKey = () => {
    const [tableData, setTableData] = useState([]);

    const API_KEY = 'AIzaSyCcUghkzabiyEkdW-RmTIvhU50j5h8OAQs'; // Замените на ваш API ключ
    const SHEET_ID = '1ZLRrV8yVxGFk6zRdmheD0gwtFoRKx1fG-MIhF7Xtuvo';

    const fetchData = async (sheetName) => {
        try {
          const response = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${sheetName}?key=${API_KEY}`);
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          return data.values; // Возвращаем массив данных
        } catch (error) {
          console.error("There was an error fetching the data: ", error);
          return []; // Возвращаем пустой массив в случае ошибки
        }
      };
      

    useEffect(() => {
        fetchData('Хамам').then(data => {
          setTableData(data);
        });  
    }, []);

      const renderTable = (tableData) => {
        // Проверяем, есть ли данные перед тем, как их рендерить
        if (!tableData || tableData.length === 0) {
          return <div>Loading...</div>; // Или другой элемент, который будет показан во время загрузки
        }
      
        return (
          <table>
            <thead>
              <tr>
                {tableData[0].map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.slice(1).map((row, index) => (
                <tr key={index}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      };



    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13];

    // Состояние для выбранного изображения
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const thumbnailsContainerRef = useRef(null);

    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
    };

    const updateSelectedImage = (direction) => {
        const currentIndex = images.indexOf(selectedImage);
        let newIndex;

        if (direction === 'left') {
            newIndex = currentIndex - 1 < 0 ? images.length - 1 : currentIndex - 1;
        } else {
            newIndex = currentIndex + 1 === images.length ? 0 : currentIndex + 1;
        }

        setSelectedImage(images[newIndex]);
    };

    const scrollThumbnails = (direction) => {
        updateSelectedImage(direction);
    };

    // Эффект для прокрутки миниатюрного изображения в видимую область
    useEffect(() => {
        const thumbnailsContainer = thumbnailsContainerRef.current;
        const activeThumbnail = thumbnailsContainer.querySelector(`.${styles.activeThumbnail}`);

        if (activeThumbnail) {
            const thumbnailOffset = activeThumbnail.offsetLeft + activeThumbnail.offsetWidth / 2;
            const scrollPosition = thumbnailOffset - thumbnailsContainer.offsetWidth / 2;
            thumbnailsContainer.scrollLeft = scrollPosition;
        }
    }, [selectedImage]);

    return (
        <div className={styles.container}>
            <p className={styles.title}>Хамами "під ключ"</p>
            <div className={styles.gallery}>
                <div className={styles.selectedImage}>
                    <img src={selectedImage} alt="Selected" />
                </div>
                <button className={`${styles.thumbnailsButton} ${styles.left}`} onClick={() => scrollThumbnails('left')}>&lt;</button>
                <div className={styles.thumbnailsWrapper}>
                    <div className={styles.thumbnails} ref={thumbnailsContainerRef}>
                        {images.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Thumbnail ${index}`}
                                onClick={() => handleThumbnailClick(image)}
                                className={image === selectedImage ? `${styles.thumbnail} ${styles.activeThumbnail}` : styles.thumbnail}
                            />
                        ))}
                    </div>
                </div>
                <button className={`${styles.thumbnailsButton} ${styles.right}`} onClick={() => scrollThumbnails('right')}>&gt;</button>
            </div>
            <p>Наша компанія займається будівництвом саун, бань та інших подібних споруд. За Вашими замовленнями ми можемо спроектувати і спорудити: <b>хамам під ключ</b>, турецьку лазню, сауну, лазню та багато іншого.</p>
            <p><b>Будівництво хамамов</b> - це процес, в якому беруть участь наші найкращі майстри. Абсолютно всі процеси (Проектування макета, підготовка до будівництва і саме <b>виготовлення хамама</b>) контролюються професіоналами з нашої компанії. Ми орієнтуємося на результат, а також на своєчасне виконання замовлення.</p>
            <p>Стелі хамамов представляють собою купола і склепіння.</p>
            <p>У наших хамамах конденсат не падає на голову, а розпечені потоки пара і води не обпалюють ноги і п'яти.</p>
            <p>Пар знаходиться на рівні сидінь, а не під стелею.</p>
            <p>Дозоване автоматичне застосування Ароми і солей створює неповторний клімат і підвищує тонус.</p>
            <p>Гармонійне сочитание подсветок і зоряного неба, перемішаних з хмарами під куполом, роблять з хамама райський куточок.</p>
            <p>Традиційно, за бажанням замовника, ми встановлюємо курни. А любителі контрастних вражень можуть відразу, не виходячи з хамама, ополоснуться «гірським водоспадом» - зі стелі на вас виллється до 70 л холодної води за 20 секунд!</p>
            <p>Користуйтеся, радійте, ми працюємо для ВАС!</p>
            <p>Великий досвід не залишає секретів для нас в області хамама, а освоєння новинки приємно здивують вас.</p>
            <p>З легким паром!</p>

            <div className={styles.price}>
                <h2>Хамам (обробка мозаїка) ціни в у.о</h2>
                {renderTable(tableData)}
            </div>

            <Contacts />
        </div>
    )
}

export default HamaKey