import React from 'react'
import styles from './Form.module.scss'

const Form = () => {
  return (
    <form className={styles.form} action="https://formspree.io/f/mvoewkgp" method="post">
        <label className={styles.label} for="name">Ім'я</label>
        <input className={styles.input} type="name" name="name" id="name" />
        <label className={styles.label} for="mobile">Телефон</label>
        <input className={styles.input} type="message" id="message"></input>
        <button className={styles.button} type="submit">Надіслати</button>
    </form>

  )
}

export default Form