import React from 'react'
import Contacts from '../Contacts/Contacts'
import styles from './Equipment.module.scss'

const Equipment = () => {
  return <div className={styles.equipmentWrapper}>
    <p>Упс...Прайси з обладнання готуються. Якщо ви хочете купити обладнання, зателефонуйте, ми запропонуємо привабливі ціни та, за бажання, оплату частинами.</p>
    <Contacts />
  </div>
}

export default Equipment