import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header/Header.jsx';
import Advantages from './components/Advantages/Advantages.jsx';
import Services from './components/Services/Services.jsx';
import About from './components/About/About.jsx';
import Objects from './components/Objects/Objects.jsx';
import TextVideo from './components/TextVideo/TextVideo.jsx';
import Contacts from './components/Contacts/Contacts.jsx';
import MessengerButtons from './components/MessengerButtons/MessengerButtons.jsx';
import SaunaKey from './components/SaunaKey/SaunaKey.jsx';
import HamaKey from './components/HamaKey/HamaKey.jsx';
import Equipment from './components/Equipment/Equipment.jsx';
import ScrollToTop from './components/ScrollToTop.jsx'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Advantages id="advantages" />
              <Services id="services" />
              <About id="about" />
              <Objects id="objects" />
              <TextVideo id="textvideo" />
              <Contacts id="contacts" />
              <MessengerButtons />
            </>
          } exact />
          <Route path="/sauna" element={<SaunaKey />} />
          <Route path="/hammam" element={<HamaKey />} />
          <Route path="/equipment" element={<Equipment />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
