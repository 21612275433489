import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Проверяем, не является ли текущий путь главной страницей
    if (pathname !== '/') {
      // Прокручиваем страницу на высоту окна браузера, если это не главная страница
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth' // Для плавной прокрутки
      });
    }
  }, [pathname]); // Эффект будет запускаться при смене маршрута

  return null;
};

export default ScrollToTop;
